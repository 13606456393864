import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div className={`bg-white ${styl.menuContainer}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9 col-md-4">
              <Link className="text-white" to="/">
                <img src="/img/logo.png" alt="Logo" className="img-fluid" />
              </Link>
            </div>
            <div className="col-3 col-md-8 ml-auto">
              <div class="header-left d-flex justify-content-end align-items-center">
                <div class="call mr-2 mt-2">
                  <a href="tel:1719-374-5361">
                    <img src="/img/phone.png" />
                  </a>
                </div>
                <div class="email mr-3 mt-2">
                  <a href="mailto:laura@lnelsonparalegal.com">
                    <img src="/img/closed-envelope-circle.png" />
                  </a>
                </div>
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
