import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import Footer from './Footer'
import style from './styles.css'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    let header
    let footer

    return (
      <div>
        <Header />
        {header}
        {children}
        {footer}
        <Footer />
      </div>
    )
  }
}

export default Layout
