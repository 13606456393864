import React from 'react'
import SampleForm from './Form'

const Footer = () => (
  <footer>
    <div id="footer">
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="contact-content text-center">
                <h2 className="section-title text-blue">CONTACT US</h2>
                <h3 className="sub-title">Leave us a message!</h3>
                <p className="section-p">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s.
                </p>
                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5bcd42f86b63453b251972bc"
                  form_classname="form-newsletter"
                >
                  <form className="mt-5">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Name"
                        id="name"
                      />
                      <label htmlFor="name">Enter Your Name</label>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="Email"
                        id="email"
                      />
                      <label htmlFor="email">Enter Your Email Address</label>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Number"
                        id="number"
                      />
                      <label htmlFor="number">Enter Your Phone Number</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="5"
                        name="Message"
                        id="message"
                      />
                      <label htmlFor="message">Your Message Here...</label>
                    </div>
                    <div className="form-group mt-4">
                      <button
                        className="btn btn-primary btn-lg sub-btn"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </SampleForm>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="about-footer">
                <h4 className="footer-title text-white">More About Company</h4>
                <p className="section-p text-white">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="social-media">
                <h4 className="footer-title text-white">Keep Connected</h4>
                <div className="social-list">
                  <div className="facebook d-flex justify-content-start">
                    <img
                      src="/img/facebook.png"
                      className="img-fluid mr-2"
                      alt="Facebook"
                    />
                    <p className="section-p mb-0 mt-1">
                      <a href="/" className="social-link">
                        Like us on Facebook
                      </a>
                    </p>
                  </div>
                  <div className="twitter mt-2 d-flex justify-content-start">
                    <img
                      src="/img/twitter.png"
                      className="img-fluid mr-2"
                      alt="Twitter"
                    />
                    <p className="section-p mb-0 mt-1">
                      <a href="/" className="social-link">
                        Follow us on Twitter
                      </a>
                    </p>
                  </div>
                  <div className="instagram mt-2 d-flex justify-content-start">
                    <img
                      src="/img/instagram.png"
                      className="img-fluid mr-2"
                      alt="Instagram"
                    />
                    <p className="section-p mb-0 mt-1">
                      <a href="/" className="social-link">
                        Follow us on Instagram
                      </a>
                    </p>
                  </div>
                  <div className="google mt-2 d-flex justify-content-start">
                    <img
                      src="/img/google-plus.png"
                      className="img-fluid mr-2"
                      alt="Google Plus"
                    />
                    <p className="section-p mb-0 mt-1">
                      <a href="/" className="social-link">
                        Add us on Google Plus
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="information">
                <h4 className="footer-title text-white">Contact Information</h4>
                <ul className="information-list">
                  <li className="location">
                    <p className="section-p text-white">
                      8 Berthe Circle, Colorado Springs, United States
                    </p>
                  </li>
                  <li className="mobile">
                    <p className="section-p text-white">
                      <a href="tel:1719-374-5361" className="information-link">
                        +1719-374-5361
                      </a>
                    </p>
                    <p />
                  </li>
                  <li className="mail">
                    <p className="section-p text-white">
                      <a
                        href="mailto:laura@lnelsonparalegal.com"
                        className="information-link"
                      >
                        laura@lnelsonparalegal.com
                      </a>
                    </p>
                    <p />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="footer-content d-flex justify-content-between">
                <p className="section-p mb-0 text-white">
                  © Copyright&nbsp;2019&nbsp;| L.L. Nelson Paralegal
                </p>
                <p class="section-p text-white mb-0">
                  {' '}
                  Designed by&nbsp;
                  <a
                    title="WebriQ Goes Mad"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.webriq.services/"
                    className="information-link"
                  >
                    WebriQ Goes Mad
                  </a>
                  &nbsp; - Powered by&nbsp;
                  <a
                    title="WebriQ"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.webriq.com/"
                    className="information-link"
                  >
                    WebriQ
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </footer>
)

export default Footer
