import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import './menu.css'

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/" className="menu-list">
          Home
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/#about" className="menu-list">
          About
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/#service" className="menu-list">
          Services
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/#contact" className="menu-list">
          Contact Us
        </Link>
      </Nav.Item>
    </Nav>
    <div class="mt-4 d-flex justify-content-start align-items-center">
      <div class="call mr-2 mt-2">
        <a href="tel:1719-374-5361">
          <img src="/img/phone.png" alt="Phone" />
        </a>
      </div>
      <div class="email mr-3 mt-2">
        <a href="mailto:laura@lnelsonparalegal.com">
          <img src="/img/closed-envelope-circle.png" alt="Mail" />
        </a>
      </div>
    </div>
    <p className="section-p mb-0 text-dark mt-4">
      © Copyright&nbsp;2019&nbsp;| L.L. Nelson Paralegal
    </p>
  </div>
)
